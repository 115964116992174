.table-page {
    padding: 10px;
    margin: 12px;
    box-shadow: 0 0 1.5rem 0 rgb(106 102 100 /30%);
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
}

.billing-table-actions-sections{
    /* background-color: #00B0E3; */
    padding: 10px 0px;
}
.billing-table-actions-sections .table-page-title{
    font-size: 22px;
    font-weight: 600;
}

.step{
    background-color: #eef4fb !important;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-radius: 10px;
    margin-right: 10px;
    font-size: 14px;
    cursor: pointer;
    margin-top: 20px;
  }

  .step1-true, .step2-true{
    font-weight: 600;
    color: #2385f4;
    cursor: default;
  }



/* BILLING TOOLBAR SECTION */

.billing-table-toolbar{
/* background-color: #22c55e; */
display: flex;
align-items: center;
justify-content: space-between;
}

.filter-billing-section{
    margin: 15px 0px;
}
.filter-label{
    font-size: 14px;
    font-weight: 600;
}
.filter-select-field {
    border: 1px solid rgb(194, 194, 194);
    padding: 4px 7px;
    font-size: 14px;
    background-color: #ffffff;
    border-radius: 5px;
    width: 150px;
}

.filter-select-field:focus {
    border: 1px solid rgb(194, 194, 194);
    outline: none;
}

.table-actions {
    display: inline-block;
}

#edit-action-icon {
    color: var(--primary);
    font-weight: 400;
    font-size: 16px;
    padding: 0px 5px;
}

#archive-action-icon {
    color: var(--orange-color);
    font-weight: 400;
    font-size: 18px;
    padding: 0px 5px;
}

#delete-action-icon {
    color: var(--red-color);
    font-weight: 400;
    font-size: 18px;
    padding: 0px 5px;
}

#view-action-icon {
    color: var(--blue-color);
    font-weight: 400;
    font-size: 20px;
    padding: 0px 5px;
}



/* ---- material ui table styles---- */

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.filter-menu-btns{
    color: #7048EC !important;
    font-family: 'Nunito', sans-serif !important; 
    font-weight: bolder !important;
    font-size: 12px !important;
}
.custom-colum-panel .MuiTypography-root{
    font-family: 'Nunito', sans-serif !important; 
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #4e5155;
  }

  .custom-colum-panel .MuiDataGrid-panelHeader{
  display: none;
  }

  .custom-colum-panel .MuiButtonBase-root{
    font-weight: 600 !important;
    font-size: 12px !important;
    color: #4e5155;
    }
  .custom-colum-panel .MuiInputBase-root{
    font-family: 'Nunito', sans-serif !important; 
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #4e5155;
  }
 
  .MuiMenuItem-root{
    font-weight: 400 !important;
    font-size: 14px !important;
  }
.MuiMenu-paper {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 0px 2px !important;
    border-radius: 10px !important;
}

.custom-table-header {
    cursor: pointer;
    color: #6F767E;
    font-weight: bold !important;
    font-size: 14px !important;
    background-color: #F5F6FA;
    margin: auto auto !important;
    height: 40px !important;
    min-width: 100;
    border: none !important;
}

.first-header {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.last-header {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.custom-table-header:hover {
    border: none !important;
}


.MuiDataGrid-sortIcon {
    display: none;
}
.MuiDataGrid-cell {
    font-weight: 600;
  }

.MuiDataGrid-root .MuiCheckbox-root:not(.Mui-checked) .MuiSvgIcon-root {
    height: 22px;
    color: #adadad;
    opacity: 0.5;
}

.MuiDataGrid-root .MuiCheckbox-root.Mui-checked .MuiSvgIcon-root {
    height: 24px;
}

.MuiDataGrid-columnHeader, .MuiDataGrid-cell, .MuiDataGrid-cellCheckbox {
    border: 0;
    outline: none !important;
}

.MuiDataGrid-footerContainer{
    display: block !important;
 }



/* DatePickerStyles.css Example custom styling  */
 .date-range-container{
    display: flex;
   justify-content: right;
}

.date-range-section{
    display: flex;
    align-items: center;
}

#date-range-trigger{
    cursor: pointer;
}

#date-range-header{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0 !important;
    color: rgba(0, 0, 0, 0.917);
    margin-right: 5px;
}


.featured-custom-datepicker {
    font-family: 'Nunito', sans-serif;
  }
  
  .featured-custom-datepicker .react-datepicker__header {
    background-color: #00B0E3;
    color: #fff !important;
  }
  
  .featured-custom-datepicker .react-datepicker__day {
    color: #333;
  }
  
  .featured-custom-datepicker .react-datepicker__day--selected {
    background-color: #00B0E3;
    color: #fff;
  }

  .featured-custom-datepicker input {
    padding: 5px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }