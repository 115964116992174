.settings-page{
    padding: 20px;
}
.settings-page-title{
   font-size: 20px;
   font-weight: bold;
}

.settings-step-false{
    background-color: #eef4fb !important;
    margin-bottom: 10px;
    padding: 8px 25px;
    border-radius: 10px;
    margin-right: 10px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 20px;
    text-align: center;
}

.settings-step-false:hover{
    border: 1px solid #1078ef;
}

.settings-step-true{
    font-weight: 600;
    color: #ffffff;
    cursor: default;
    background-color: #1078ef !important;
    margin-bottom: 10px;
    padding: 8px 25px;
    border-radius: 10px;
    margin-right: 10px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 20px;
  }

.apikey-callback-section{
   border-radius: 5px;
   margin-top: 20px;
   padding-bottom: 20px;
}

#apikey-callback-section-title{
    background-color: rgba(216, 216, 216, 0.467);
    padding: 7px;
    font-weight: 600;
    font-size: 16px;
}

.apikey-credetial-sec{
    border-radius: 6px;
    padding: 15px 20px;
    margin-top: 20px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    background-color: #ffffff;
}

.apikey-credetial-title{
    font-size: 16px;
    font-weight: bold;
}

.apikey-input-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 2px 7px;
    background-color: rgba(239, 237, 237, 0.646);
    border-radius: 7px;
    border: 1px solid rgb(223, 223, 223);
    margin-top: 4px !important;
    margin-bottom: 20px;
    width: 70%;
    max-width: 100%;
}

#apikey-input-hidden-value{
    display: flex;
    justify-content: center;
    align-items: center;
}
#star-key-value{
    padding-top: 8px;
    margin-right: 10px;
}

#apikey-input-visible-value{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 0px;
    font-size: 14px;
    font-weight: 600;
}

#apikey-input-visible-value span{
    margin-left: 10px;
}

#visibility-eye{
 cursor: pointer;
 padding-left: 5px;
 padding-right: 5px;
}
#copy-btn{
    cursor: pointer;
}
#credentials-btns{
    margin-left: 20px;
}

#genearte-key-btn{
    border: none;
    border-radius: 5px;
    padding: 6px 10px;
    font-weight: 600;
    background-color: #00B0E3;
    cursor: pointer;
    color: #ffffff;
    font-size: 13px;
}

.balance-alert-input{
    border-radius: 5px;
    padding: 7px 7px;
    margin: 15px 0px;
    width: 65%;
    border: 1px solid rgb(168, 168, 168);
    border-radius: 5px;
}

.balance-alert-input:focus{
    outline: none;
    border-color: #00B0E3;
    background-color: rgb(255, 255, 255);
}

/* password submit button style */
.submit-btn {
    padding: 10px 7px;
    font-size: 14px;
    font-weight: bold;
    color: white;
    background-color: #00B0E3;
    border: none;
    border-radius: 8px;
    margin-top: 20px;
    width: 120px;
    cursor: pointer;
}

.submit-btn-disabled{
    cursor: not-allowed;
    padding: 10px 7px;
    border: none;
    border-radius: 8px;
    margin-top: 20px;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00B0E3;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 16px !important;
    height: 16px !important;
    border-radius: 50%;
    border: 2px solid rgb(255, 255, 255) !important;
    border-color: rgb(255, 255, 255) transparent rgb(254, 255, 255) transparent !important;
    animation: lds-dual-ring 0.9s linear infinite !important;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }



/* password input section style */
.password-form-group {
    display: flex;
    flex-direction: column;
}

.password-form-label {
    font-size: 14px !important;
    color: rgb(38, 39, 39);
    font-weight: 600 !important;
    padding-bottom: 0px !important;
}

.password-form-control {
    padding: 6px 5px;
    border-radius: 6px;
    border: 1px solid rgb(206, 206, 206);
    font-size: 14px !important;
    background-color: rgba(219, 219, 219, 0.322);
}

.password-form-control:focus {
    outline: none;
    /* background-color: #00B0E3; */
    border-color: #00B0E3;
    background-color: rgb(255, 255, 255);
}

#password-error {
    font-size: 12px;
    color: rgb(221, 34, 34);
    margin-top: 5px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}


/* password modal */
.create-modal-inactive {
    display: none;
    animation: hide-modal .7s ease-in-out;
}

.create-modal-active {
    display: block;
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 7;
}

.view-modal-content {
    margin-top: 20px !important;
    background-color: #ffff;
    margin: auto auto;
    width: 50%;
    height: auto;
    padding: 10px 20px;
    border-radius: 10px;
    animation: show-modal .7s ease-in-out;
    display: grid;
    grid-template-rows: repeat(auto-fit, minmax(20px, 1fr));
    grid-gap: 10px;
    z-index: 7;
}

.modal-title {
    font-size: 20px;
    padding: 5px 0px;
    margin-bottom: 12px;
    opacity: 0.7;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dialog-footer {
    display: flex;
    justify-content: flex-end;
}

#close-button {
    background-color: rgb(238, 25, 25);
    border: none;
    color: white;
    font-weight: bolder !important;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    padding: 2px;
    font-weight: bold;
}