.whatsapp-manager{
    align-items: center;
    padding: 0px 10px;
    padding-top: 20px;
}

.setup-section{
  height: max-content !important;
  position: relative !important;
}

.setup-title{
    font-size: 22px;
    font-weight: bold;
}

.setup-title-p{
    font-size: 15px;
}
.points-to-note{
   margin-top: 5px;
}
.points-to-note p {
   font-weight: bold;
   font-size: 16px;
}
.points-to-note li {
   font-size: 15px;
   margin-left: 15px;
}

#support-email{
   color: rgb(0, 162, 255);
}

.setup-steps-section{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: 10px;
    margin-top: 15px;
    margin-bottom: 10px;
    position: relative;
}

#golive-step{
   width: 350px;
   height: min-content;
   background-color: #b2d2f74d !important;
   font-size: 14px;
   font-weight: 500;
   /* display: flex; */
   align-items: center;
   padding: 10px;
   border-radius: 10px;
   position: relative !important;
}

#golive-step img{
    width: 40px;
 }

 .facebook-login-btn-section{
   position: relative;
 }

 .facebook-login-btn{
   margin-top: 10px;
   background-color: rgb(0, 94, 255);
   padding: 6px 18px;
   color: #ffff;
   border: none;
   border-radius: 7px;
   font-size: 14px;
   cursor: pointer;
   display: flex;
   justify-content: space-between;
   align-items: center;
 }


 #register-table-title{
    font-size: 20px;
    font-weight: 600;
    margin-left: 8px;
 }

 #table-action-edit-btn{
    color: rgba(0, 72, 255, 0.82);
    font-size: 22px;
 }

 #table-action-delete-btn{
    color: rgba(255, 0, 47, 0.82);
    font-size: 22px;
 }

 .registered-number-table-section{
   box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
   border-radius: 5px;
   /* background-color: antiquewhite; */
 }

 .numbers-table-section .MuiDataGrid-footerContainer{
    display: none !important;
 }


 .connected-numbers-table{
   box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
   height:255px;
   padding: 10px 10px;
   border-radius: 6px;
   margin-left: 10px;
 }

 .connected-numbers-table-title{
   font-size: 18px;
   font-weight: bold;
 }
 .connected-numbers-table-description{
   font-size: 12px;
   font-weight: 500;
   margin-bottom: 10px;
 }
 .connected-numbers-table-section{
   background-color: #fefefe !important;
   border-radius: 6px;
   padding: 0px 0px;
 }
