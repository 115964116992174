.welcome-page{
    height: 100vh;
    padding: 10px 10px;
}

#greeting-message{
    font-size: 25px;
    font-weight: bold;
}

#instruction-section-header{
   font-size: 20px;
   font-weight: bold;
   margin-bottom: 15px;
}

#api-name{
    color: rgba(60, 105, 243, 0.994);
    font-weight: bold;
}

.steps-section{
    height: max-content !important;
    border-radius: 6px;
    padding: 10px 10px;
    margin-top: 20px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

#step-instructions{
  padding: 1rem; 
  background-color: #50bcf654;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  color: #161616ec;
}

  #join-code-link{
    text-decoration: none;
    color: #1479ed;
    font-weight: bold;
    padding: 5px;
    margin-left: 2px;
    border-radius: 5px;  
  }
  #join-code-link:hover{
    text-decoration: underline;
  }

  