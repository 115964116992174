.forgot-password-page {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F3F3F9 !important;
}

.forgot-password-section{
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    border-radius: 10px;
    padding: 10px;
    
}

.forgot-pass-svg{
    width: 320px;
}

.forgot-pass-form-sec{
    display: flex;

}
.forgot-password-form {
    background-color: rgb(255, 255, 255);
    height: auto;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    align-items: center;
    margin: auto auto;
}

.forgot-password-form-header{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0px;
    color: #38383a;
}

.forgot-password-p{
    font-size: 14px;
    color: #5a6370;
}

.forgot-password-label{
    color: #38383a;
    font-size: 14px;
    font-weight: 600;
}

.forgot-password-input {
    padding: 7px 10px;
    border-radius: 5px;
    border: 1px solid rgba(211, 211, 211, 0.377);
    background-color: #F3F3F9 !important;
    font-size: 14px;
}

.forgot-password-input:focus {
    outline: none;
    border: 1.7px solid #00B4DB;
    background-color: #ffffff !important;
}

.forgot-password-button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    border: none;
    background-color: #00B4DB;
    color: #fff;
    cursor: pointer;
    margin-top: 0px;
    margin-bottom: 15px;
}

.forgot-password-disable {
    width: 100%;
    padding: 10px 10px;
    border-radius: 5px;
    border: none;
    background-color: #00B4DB;
    cursor: not-allowed;
    display: flex;
    justify-content: center;
}

.go-to-login{
    text-decoration: none;
    color: #00B4DB;
    font-size: 14px;
    font-weight: 600;

}

#form-error {
    color: var(--danger);
    font-size: 12px;
}

@media screen and (max-width: 900px) {
    .forgot-password-page{
        padding: 10px;
    }
}


@media screen and (max-width: 720px) {
    .forgot-password-section{
       display: flex;
       flex-direction: column;
      
    }
}
@media screen and (max-width: 340px) {
    .forgot-pass-svg{
        width: 250px;
    }
    
}




/* Email Recovery modal */
.email-modal-inactive {
    display: none;
}

.email-modal-active {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000;
}

.delete-modal {
    position: relative;
    width: 500px;
    height: 210px;
    left: 0;
    top: 20%;
    margin: auto auto !important;
    background: #fff;
    border-radius: 5px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    text-align: center;
    overflow: hidden;
    animation: show-modal .7s ease-in-out;
    margin-top: 20px !important;
    z-index: 1000;
}

.modal.hide {
    animation: hide-modal .6s ease-in-out both;
}

#delete-img {
    margin-top: 24px;
}


.email-message {
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    margin: 0;
}


.dismiss-button {
    width: 40%;
    background: #1d45b4;
    border: none;
    border-radius: 5px;
    color: #fff;
    line-height: 40px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: background .3s ease-in-out;
}

.dismiss-button:hover {
    background: #4c6fcf;
}


@keyframes show-modal {
    0% {
        transform: scale(0);
    }

    60% {
        transform: scale(1.1);
    }

    80% {
        transform: scale(.95);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes hide-modal {
    0% {
        transform: scale(1);
    }

    20% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(0);
    }
}