
.navbar {
  padding: 16px 1%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  width: auto;
  background-color: rgb(255, 255, 255);
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px; */
  z-index: 2;
}

.balance-section{
  display: flex;
  align-items: center;
  
}

.topup-btn{
  background-color: #EB1947;
  padding: 2px 10px;
  border-radius: 4px;
  color: #ffffff;
  margin-left: 5px;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}
.topup-btn:hover{

 opacity: 0.7;
}

.toggle-section {
  display: flex;
  align-items: center;
  text-align: center;
}

.logo-link {
  margin-top: 5px;
  margin-bottom: 10px;
}

.nav-company-logo {
  width: 120px;
  object-fit: initial;
  display: none;
}

#toogle-icons {
  font-size: 2rem;
  margin-right: 10px;
  cursor: pointer;
  display: none;
}

@media (max-width: 1080px) {
  .navbar {
    padding: 15px 1%;
  }
  #nav-greetings {
    margin-top: 10px;
    margin-left: 10px;
  }
  .nav-company-logo {
    display: block;
  }
  #toogle-icons {
    display: block;
  }
}

.user-conversations{
  padding: 0px 0px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: #666666;
}

.user-credits{
  /* background-color: #00B0E3; */
  padding: 0px 0px;
  border-radius: 4px;
  display: flex;
  align-items: center;

}

#credit-currency{
  font-weight: bold;
  font-size: 14px;
  padding-right: 5px;
  color: #232222;
}
#credit-amount{
  font-weight: 400;
  font-size: 14px;
  padding-right: 5px;
  font-weight: bold;
  color: #6f6f6f;
}



/* --- profile section styles --- */
.sidebar-profile-section {
  padding: 0px 0px;
  color: #292D32;
}

.sidebar-profile-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 0px 5px;
  text-decoration: none;
  color: #292D32;
  border-radius: 6px;
  width: 280px;
}

#sidebar-profile-details {
  display: flex;
}

#profile-name {
  font-size: 14px;
  font-weight: bold;
}

#profile-email {
  font-size: 14px;
  color: #1E1E1E;
}

.sidebar-profile-links {
  background-color: #ffffff;
  padding: 8px 5px !important;
  margin: 15px 18px;
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: 15%;
  display: none;
  animation: fadeIn 0.5s;
  position: absolute;
  left: 82%;
}

#profile-link {
  display: flex;
  align-items: center;
  text-align: right !important;
  text-decoration: none !important;
  font-size: 14px;
  font-weight: bold;
  color: black;
  text-decoration: none !important;
  list-style: none !important;
  padding: 6px 10px;
  font-weight: 500;
  margin-bottom: 3px;
  margin-left: 0px;
  border-radius: 6px;
}

#profile-link:hover {
  color: #ffff !important;
  background-color: #00B0E3;
}

#profile-link:hover img {
  filter: brightness(0) invert(1);
}

#profile-pic {
  margin-right: 15px;
}
#profile-icon{
  width: 20px;
  margin-right: 5px;
}

.logout-btn{
  color: var(--danger) !important;
  background-color: #dc271e1a;
  font-weight: bold !important;
  width: 100%;
  border: none;
  cursor: pointer;
}

.logout-btn:hover{
  color: var(--danger) !important;
  background-color: #dc2a21c6 !important;
  font-weight: bold !important;
}

@media (max-width: 1080px) {
  #sidebar-hide-btn {
      display: block;
  }
}