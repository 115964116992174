
.sidebar {
    min-height: 100vh;
    background-color: rgb(255, 255, 255);
    z-index: 1;
    width: 300px;
    display: block;
    padding: 10px 2%;
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}

.company-name-sec{
    background-color: #e30057;
    display: flex;
    text-align: center;
    align-items: center;
    padding: 4px 12px;
    margin: 0px 5px;
    text-decoration: none;
    color: rgb(82, 82, 82);
    font-size: 15px;
    font-weight: 600;
    border-radius: 5px;
    color: #F5F6FA;
}

.logo-section {
    margin: 5px 8px;
    padding-bottom: 10px;
}

.sidebar-logo {
    padding: 0px 5px;
    margin: 2px 10px;
    width: 120px;
    object-fit: initial;
}


#h-icon {
    padding-right: 6px;
    font-weight: 500 !important;
}


.heading-link {
    cursor: pointer;
    display: flex;
    text-align: center;
    align-items: center;
    padding: 6px 12px;
    margin: 12px 11px;
    text-decoration: none;
    color: rgb(82, 82, 82);
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
}

.heading-link:hover {
    color: #1a1919;
    font-weight: bold;
    /* background-color: #e0e0e05c; */
    border-radius: 7px;
}

.active {
    color: #35a5f5f8;
    font-weight: bold;
    /* background-color: #35a5f53a; */
    border-radius: 7px;
}



#icons {
    padding: 0px 5px;
}

.sidebar-menu-section {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

