.signup-page {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
}

.signup-left-side {
    min-height: 100vh;
    height: 100vh;
    width: 50%;
    display: flex;
    align-items: center;
    padding: 35px 4%;
    background-image: linear-gradient(to bottom right, #ea384c, #0EA5E9);
}

.signup-left-content-header {
    font-size: 36px;
    padding: 10px 0px;
    font-weight: bold;
    color: white;
}

.signup-left-content-paragragh {
    font-size: 16px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.848);
}

#signup-content-list {
    font-size: 20px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.82);
    padding: 10px 20px;
}

#signup-content-item {
    font-size: 16px;
    font-weight: 600;
    padding: 5px 0px;
    color: rgba(255, 255, 255, 0.82);
}

.signup-form-section {
    background-color: #fff;
    width: 50%;
    display: flex;
    justify-content: center;
    min-height: auto;
    border-radius: 10px;
    padding: 10px 10%;
}

.inputs-section {
    padding: 0 !important;
}

.upper-inputs {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 10px;
}

.signup-form {
    margin: auto auto !important;
    padding: 0px 12px;
    width: 100%;
    background-color: #fff;
}

.signup-welcome-header {
    font-size: 22px;
    padding: 10px 0px;
    font-weight: bold;
    color: #ea384c;
}

.signup-input-group {
    display: flex;
    flex-direction: column;
    margin: 0px 0px 10px 0px;
}

.signup-input {
    padding: 7px 6px;
    font-size: 14px;
    border-radius: 5px;
    outline: none !important;
    border: 1px solid rgba(152, 152, 152, 0.399);
    font-weight: 500;
}

.signup-input:focus {
    outline: none !important;
    border: 1px solid rgb(0, 174, 255);
}

.signup-button {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 6px;
    border: none;
    background-color: rgb(0, 174, 255);
    color: #fff;
    cursor: pointer;
    margin-top: 5px;
}

.signup-button:hover {
    opacity: 0.6 !important;
    transition: opacity .3s ease-in-out;
}

.signup-button-disable {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    border: none;
    background-color: rgb(0, 174, 255);
    color: #fff;
    cursor: pointer;
    margin-top: 10px;
    opacity: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signup-actions {
    margin: 5px 0px;
}

.signup-section {
    text-align: center;
}

.login-link {
    font-weight: 600;
    text-decoration: none;
    color: #0083B0;
}

#signup-form-error {
    color: rgb(234, 25, 25);
    font-size: 12px;
}

.terms-condition-section {
    padding-top: 5px;
    text-align: center;
}

.terms-conditions {
    font-size: 12px;
}

#terms-privacy {
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    color: #0078af;
}

.terms-condition-modal {
    height: 450px;
    overflow-y: scroll;
}

/* LOADING ANIMATION */
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 18px !important;
    height: 18px !important;
    border-radius: 50%;
    border: 2px solid rgb(255, 255, 255) !important;
    border-color: rgb(255, 255, 255) transparent rgb(254, 255, 255) transparent !important;
    animation: lds-dual-ring 0.9s linear infinite !important;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
    .signup-left-side {
        display: none;
    }

    .signup-form-section {
        width: 90%;
        padding: 10px 5%;
    }

    .upper-inputs {
        grid-template-columns: 1fr;
    }

    .signup-input {
        font-size: 14px;
    }

    .signup-welcome-header {
        font-size: 18px;
    }

    #signup-content-list {
        font-size: 16px;
    }
}

@media screen and (max-width: 600px) {
    .signup-form-section {
        width: 92%;
        padding: 10px 4%;
    }

    .signup-form {
        width: 100%;
    }

    .signup-button {
        font-size: 16px;
    }

    .signup-button-disable {
        font-size: 16px;
    }
}
