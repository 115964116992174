
.container {
  max-width: 380px;
  margin: 50px auto;
  overflow: hidden;
}

.printer-top {
  z-index: 1;
  border: 6px solid #666666;
  height: 6px;
  border-bottom: 0;
  border-radius: 6px 6px 0 0;
  background: #333333;
}

.printer-bottom {
  z-index: 0;
  border: 6px solid #666666;
  height: 6px;
  border-top: 0;
  border-radius: 0 0 6px 6px;
  background: #333333;
}

.paper-container {
  position: relative;
  overflow: hidden;
  height: 467px;
}

.paper {
  background: #ffffff;
  font-family: 'Poppins', sans-serif;
  height: 447px;
  position: relative;
  margin: 0 12px;
  margin-top: -12px;
  animation: print 1500ms cubic-bezier(0.68, -0.55, 0.265, 0.9);
  -moz-animation: print 1500ms cubic-bezier(0.68, -0.55, 0.265, 0.9) infinite;
}

.main-contents {
  margin: 0 12px;
  padding: 24px;
}


.jagged-edge {
  position: relative;
  height: 20px;
  width: 100%;
  margin-top: -1px;
}

.jagged-edge:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  height: 20px;
  background: linear-gradient(45deg,
      transparent 33.333%,
      #ffffff 33.333%,
      #ffffff 66.667%,
      transparent 66.667%),
      linear-gradient(-45deg,
      transparent 33.333%,
      #ffffff 33.333%,
      #ffffff 66.667%,
      transparent 66.667%);
  background-size: 16px 40px;
  background-position: 0 -20px;
}

.success-payment-icon {
  text-align: center;
  font-size: 48px;
  height: 72px;
  background: #359d00;
  border-radius: 50%;
  width: 72px;
  height: 72px;
  margin: 16px auto;
  color: #fff;
}

.failed-payment-icon {
  text-align: center;
  /* width: 72px; */
  height: 72px;
  margin: 16px auto;
  display: flex;
  justify-content: center;

}
.success-title {
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  color: #666;
  font-weight: bold;
  margin-bottom: 16px;
}

.success-description {
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  line-height: 21px;
  color: #999;
  text-align: center;
  margin-bottom: 24px;
}

.order-details {
  text-align: center;
  color: #333;
  font-weight: bold;

}

.order-number-label {
  font-size: 18px;
  margin-bottom: 8px;
}

.order-number {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  line-height: 48px;
  font-size: 14px;
  padding: 8px 0;
  margin-bottom: 10px;
}

.complement {
  font-size: 18px;
  margin-bottom: 8px;
  color: #32a852;
}


.continue-payment-btn {
  height: 100%; 
  display: inline-block;
  background-color: lightblue;
  text-align: center;
  line-height: 100%;
  text-decoration: none; 
  padding: 10px 70px;
  background-color: #00B0E3;
  width: 100% !important;
  border-radius: 5px;
  color: white;
}



@keyframes print {
  0% {
      transform: translateY(-90%);
  }

  100% {
      transform: translateY(0%);
  }
}

@-webkit-keyframes print {
  0% {
      -webkit-transform: translateY(-90%);
  }

  100% {
      -webkit-transform: translateY(0%);
  }
}

@-moz-keyframes print {
  0% {
      -moz-transform: translateY(-90%);
  }

  100% {
      -moz-transform: translateY(0%);
  }
}

@-ms-keyframes print {
  0% {
      -ms-transform: translateY(-90%);
  }

  100% {
      -ms-transform: translateY(0%);
  }
}




/* --- loader css section --- */

.payment-loader-section{
  display: block;
  position: relative;
  margin: auto auto !important;
  text-align: center;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #247ff6;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}