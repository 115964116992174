.table-page-api {
    /* padding: 10px; */
    margin: 5px 10px;
    /* box-shadow: 0 0 1.5rem 0 rgb(106 102 100 /30%); */
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    height: calc(100% - 20px);
}

.table-page-title-api{
    font-size: 22px;
    font-weight: bold;
    margin-left: 10px;
}

.api-step{
    background-color: #eef4fb !important;
    margin-bottom: 10px;
    padding: 4px 10px;
    border-radius: 8px;
    margin-right: 10px;
    font-size: 14px;
    cursor: pointer;
    margin-top: 10px;
    font-weight: bold;
    color: #1b1b1b6b;
  }

  .api-step:hover{
    border: 1px solid #2385f4;
    cursor: pointer;
  }

  .step1-true, .step2-true{
    font-weight: bold;
    color: #2385f4;
    cursor: pointer;
    border: 1px solid #2385f4;
  }

  .table-toolbar-api{
    display: flex;
    justify-content: space-between;
    padding: 5px 0px;
  }


.filter-messages-section-api{
    margin: 0px 10px;
}
.filter-label-api{
    font-size: 14px;
    font-weight: 600;
}
.filter-select-field-api {
    border: 1px solid rgb(194, 194, 194);
    padding: 2px 7px;
    font-size: 14px;
    background-color: #ffffff;
    border-radius: 5px;
    width: 150px;
}

.filter-select-field-api:focus {
    border: 1px solid rgb(194, 194, 194);
    outline: none;
}

.table-input-api{
    border: 1px solid rgb(194, 194, 194);
    padding: 3px 7px;
    font-size: 14px;
    background-color: #ffffff;
    border-radius: 5px;
}

.table-input-api:focus{
    outline: none !important;
}

/*------- table status values styling------- */
#status-RECEIVED{
    background-color: #22c55e3a;
    color: #22c55e;
    font-weight: bold;
    padding: 2px 10px;
    border-radius: 5px;
    font-size: 11px;
    text-align: center;
    text-transform: capitalize !important;
}


#status-SENT {
    background-color: #2274c53a !important;
    color: #2276c5 !important;
    font-weight: bold;
    padding: 2px 10px;
    border-radius: 10px;
    font-size: 11px;
    text-align: center;
    text-transform: capitalize !important;
}


#status-READ{
    background-color: #22c55e3a;
    color: #22c55e;
    font-weight: bold;
    padding: 2px 10px;
    border-radius: 5px;
    font-size: 11px;
    text-align: center;
    text-transform: capitalize !important;
    width: 200px !important;
}


#status-DELIVERED {
    background-color: #2274c53a !important;
    color: #2276c5 !important;
    font-weight: bold;
    padding: 2px 10px;
    border-radius: 6px;
    font-size: 11px;
    text-align: center;
    text-transform: capitalize !important;
}

#status-FAILED {
    background-color: #e014143a !important;
    color: #c52222 !important;
    font-weight: bold;
    padding: 2px 10px;
    border-radius: 6px;
    font-size: 11px;
    text-align: center;
    text-transform: capitalize !important;
}

#direction-INBOUND {
    background-color: #4463ef3f;
    color: #4455ef;
    font-weight: bold;
    padding: 2px 5px;
    border-radius: 6px;
    font-size: 11px;
    width: 100px;
    text-align: center;
    text-transform: capitalize !important;
}

#direction-OUTBOUND {
    background-color: #ef44443f;
    color: #ef4444;
    font-weight: bold;
    padding: 2px 5px;
    border-radius: 10px;
    font-size: 11px;
    width: 100px;
    text-align: center;
    text-transform: uppercase  !important;
}


/* ant design custom styling */
.custom-table .ant-table-thead > tr > th {
    font-size: 14px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.4);
    padding: 9px 16px;
  }

.custom-table .ant-table-tbody > tr > td {
    font-size: 14px;
    font-weight: 600;
    color: #1b1b1b; 
    padding: 9px 16px; 
  }

.custom-pagination{
    font-size: 13px;
    font-weight: 600;
}
.custom-pagination .ant-select-selector {
  padding: 5px 10px !important;
  font-size: 13px; /* Adjust the padding as needed */
  height: 20px; /* Adjust as needed */
  line-height: 20px; /* Match the height */
}

.custom-pagination .ant-pagination-item {
  height: 20px; /* Adjust as needed */
  line-height: 20px; /* Match the height */
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Adjust the ellipsis height (if applicable) */
.custom-pagination .ant-pagination-item-ellipsis {
  line-height: 20px; /* Match the height of other elements */
}