
.form-group {
    display: flex;
    flex-direction: column;
}

.form-label {
    font-size: 14px !important;
    color: rgb(38, 39, 39);
    font-weight: 600 !important;
    padding-bottom: 0px !important;
}

.form-control {
    padding: 6px 5px;
    border-radius: 6px;
    border: 1px solid rgb(206, 206, 206);
    font-size: 14px !important;
    background-color: rgba(219, 219, 219, 0.322);
}

.form-control:focus {
    outline: none;
    border-color: var(--blue-color);
    background-color: rgb(255, 255, 255);
}

#error {
    font-size: 12px;
    color: rgb(221, 34, 34);
    margin-top: 5px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}