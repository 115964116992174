
.login-page {
    min-height:100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
}

.company-login{
    width: 120px;
}

.login-left-side {
    width:50%;
    min-height:100vh;
    display: flex;
    align-items: center;
    height:100%;
    padding: 20px 5%;
    background-image: linear-gradient(to bottom right, #ea384c, #0EA5E9);
}

.login-background{
    width: 80%;
}

.left-content {
    width: 100%;
    margin: auto;
    padding: 0px 10px;
}

.left-content-header {
    font-size: 45px;
    padding: 10px 0px;
    font-weight: bold;
    color: white;
}

#content-list {
    font-size: 20px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.82);
    padding: 10px 20px;
}

#content-item {
    font-size: 16px;
    font-weight: 600;
    padding: 5px 0px;
    color: rgba(255, 255, 255, 0.82);
}

#website-link {
    background-color: rgb(0, 174, 255);
    text-decoration: none;
    color: white;
    padding: 6px 20px;
    margin-left: 18px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 15px;
}

#docs-website-link {
    background-color: transparent;
    border: 1px solid rgb(210, 210, 255);
    text-decoration: none;
    color: white;
    padding: 6px 20px;
    margin-left: 18px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 15px;
}

#docs-website-link:hover {
    background-color: white;
    color: rgb(0, 174, 255);
    border: none;
}

.login-lipachat-logo {
    width: 120px;
    display: block;
}

.login-welcome-header {
    font-size: 22px;
    padding: 10px 0px;
    font-weight: bold;
    color: #ea384c;
}
.login-form-section {
    background-color: #fff;
    width:50%;
    display: flex;
    justify-content: center;
    min-height: auto;
    border-radius: 10px;
    padding: 10px 10%;
}

.login-form {
    margin: auto auto !important;
    padding: 20px 20px;
    width: 100%;
}


.input-group {
    display: flex;
    flex-direction: column;
    margin: 15px 0px;
}

.login-label {
    color: rgba(40, 39, 39, 0.825);
    font-size: 14px;
    margin-bottom: 2px;
    font-weight: 600;
}

.login-input {
    padding: 7px 6px;
    font-size: 14px;
    font-size: 13px !important;
    border-radius: 5px;
    outline: none !important;
    border: 1px solid rgba(152, 152, 152, 0.399);
    font-weight: 500;
}

.login-input:focus {
    outline: none !important;
    border: 1px solid rgb(0, 174, 255);
   
}

.login-button {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
    border: none;
    background-color: rgb(0, 174, 255);
    color: #fff;
    cursor: pointer;
    margin-top: 10px;
}

.login-button:hover {
    opacity: 0.7;
    transition: opacity .3s ease-in-out;
}

.login-disable {
    width: 100%;
    padding: 10px 10px;
    border-radius: 5px;
    border: none;
    background-color: rgb(0, 174, 255);
    margin-top: 10px;
    cursor: not-allowed;
    display: flex;
    justify-content: center;
    opacity: 0.7;
}



.forgot-link {
    text-decoration: none;
    color: #006db0;
    font-size: 14px;
    background-color: transparent !important;
}

.login-actions {
    margin-top: 15px;
}

.signup-section {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
}

.signup-link {
    font-weight: 600;
    text-decoration: none;
    color: #0083B0;
}


#login-form-error {
    color: rgb(238, 44, 44);
    font-size: 12px;
}

@media screen and (max-width: 768px) {
   
    .login-left-side{
        display: none;
    }

    .login-form-section {
        width: 80%;
    }

}

@media screen and (max-width: 600px) {
    .login-form {
        width: 90%;
    }

}



/* LOADING ANIMATION */
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 16px !important;
    height: 16px !important;
    border-radius: 50%;
    border: 2px solid rgb(255, 255, 255) !important;
    border-color: rgb(255, 255, 255) transparent rgb(254, 255, 255) transparent !important;
    animation: lds-dual-ring 0.9s linear infinite !important;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}