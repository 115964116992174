
.chat-conversation-section{
    background-color: #ffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    min-height: 500px;
    max-height: 500px;
    width: 85%;
    margin: 20px 40px;
    border-radius: 10px;
    padding: 10px 10px;
    display: flex;
    justify-content: space-between;
    overflow-y: hidden;
}

.contacts-section{
    background-color: #eef4fb;
    width: 35%;
    padding: 10px;
    border-radius: 10px;
    min-height: 460px !important;
    max-height: 480px;
    overflow-y: scroll;
}
#contact-search{
  padding: 7px 10px;
  border: 1px solid #dddddd !important;;
  border-radius: 7px;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}
#contact-search:focus{
    outline: none !important;
  }
.contacts-list{
    list-style: none;   
}

.contacts-list li{
    display: flex;
    align-items: center;
    padding: 10px 0px;
    border-bottom: 1px solid #a3c7f178;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.581);
    font-size: 16px;
    font-weight: bold;
}
.contacts-list li:hover{
  background-color: #ffff;
}

.contacts-list li img{
  width: 30px;
  margin-right: 10px;
}

.chats-conversation-section{
  min-width: 64%;
  
}

.conversation-section{
    width: 100%;
    height: 90%;
    background-color: #eef4fb;
    border-radius: 10px;
    padding: 10px 20px;
    overflow-y: scroll !important;
}

.contact-profile-section{
  background-color: #a6d7ff86;
  position: sticky;
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 0px 5px;
  display: flex;
  justify-content: space-between;
}

.contact-profile-section p{
  display: flex;
  align-items: center;
}
.contact-profile-section img{
 width: 30px;
 margin-right: 10px;
}

.messages-section{
  display: flex;
  flex-direction: column;
}


/* incoming messages style */
.incoming-message-sec{
  display: flex;
  align-items: flex-start;
}
.incoming-message-div{
  margin-left: 10px;
  max-width: 60%;
}

.incoming-message-content{
  background-color: #c8c8c888;
  padding: 5px 10px;
  border-radius: 7px;
  font-size: 14px;
}


/* outgoing messages style */
.outgoing-message-sec{
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin: 20px 0px; 
}
.outgoing-message-div{
  margin-right: 10px;
  max-width: 60%;
}

.outgoing-message-content{
  background-color: rgb(37, 98, 241);
  padding: 5px 10px;
  border-radius: 7px;
  font-size: 14px;
  color: white;
}

.message-timestamp{
  color: #626262c5;
  font-size: 14px;
  margin-left: 3px;
  margin: 3px;
}

.incoming-timestamp{
  text-align: right;
}


.empty-conversation-section{
  width: 100%;
  min-height: 90%;
  background-color: #eef4fb;
  border-radius: 10px;
  padding: 10px 20px;
  overflow-y: scroll !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}